import React from "react"
import dayjs from "dayjs"

import { Box, Flex, Heading, Paragraph, Split, Text } from "components"
import { prismicColor } from "utils"

const IntroSection = ({ data, id }) => {
  return (
    <>
      {/* We only render the section if it has props set */}
      {(data.title || data.bodyContent) && (
        <Split id={id} image={data.image}>
          <Flex alignItems="center" mb={4}>
            {/* Blog Tag and Date */}
            {data.blogTag && data.blogTag.name && (
              <Box position="relative" py="6px" px="layout.1" mr={4}>
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  width="100%"
                  height="100%"
                  bg={prismicColor(data.blogTag.color).background}
                  opacity={0.15}
                />
                <Text
                  position="relative"
                  size={400}
                  color={prismicColor(data.blogTag.color).background}
                  style={{ textTransform: "lowercase" }}
                >
                  {data.blogTag.name}
                </Text>
              </Box>
            )}
            {data.date && (
              <Text color="brand.tertiary">
                {dayjs(data.date).format("MMMM DD, YYYY")}
              </Text>
            )}
          </Flex>
          {/* Title */}
          {data.title && (
            <Heading size={900}>
              <Box as="span" color="text">
                {data.title}
              </Box>
            </Heading>
          )}
          {/* Content */}
          {data.bodyContent && (
            <Paragraph
              mt={["layout.4", "layout.5", "layout.5", "layout.8"]}
              style={{ whiteSpace: "pre-line" }}
            >
              {data.bodyContent}
            </Paragraph>
          )}
        </Split>
      )}
    </>
  )
}

export default IntroSection
