import React from "react"

import { Heading, Wrapper } from "components"
import { prismicColor } from "utils"

function Quote({ color, quote }) {
  if (quote && quote.text)
    return (
      <Wrapper>
        <Heading size={900} color={prismicColor(color).background}>
          {quote.text}
        </Heading>
      </Wrapper>
    )
  else return null
}

export default Quote
