import React from "react"

import { AspectRatio, Box, Wrapper } from "components"

function Video({ embed }) {
  let EMBED = null

  // Convert regular YouTube link (which Prismic allows) into a proper embed link
  if (embed && embed.embed_url && embed.embed_url.includes("watch?v=")) {
    EMBED = embed.embed_url.replace("watch?v=", "embed/")
  }

  if (EMBED)
    return (
      <Wrapper>
        <Box maxWidth={800}>
          <AspectRatio ratio="16:9">
            <iframe
              id="ytplayer"
              type="text/html"
              src={EMBED}
              frameborder="0"
              style={{ width: "100%", height: "100%" }}
            />
          </AspectRatio>
        </Box>
      </Wrapper>
    )
  else return null
}

export default Video
