import React from "react"

import { RichText as RichTextComponent, Wrapper } from "components"

function RichText({ content }) {
  if (content && content.html)
    return (
      <Wrapper>
        <RichTextComponent
          content={{
            html: content.html,
          }}
          size={500}
          maxWidth={800}
        />
      </Wrapper>
    )
  else return null
}

export default RichText
