import React from "react"
import { graphql } from "gatsby"

import { NextPage, SEO } from "components"
import { CallToAction, Download, Gallery, Quote, RichText, Video } from "slices"

import IntroSection from "./_introSection"

function BlogPostTemplate({ data }) {
  let pageData = {}

  if (data && data.page && data.page.data) {
    pageData = data.page.data
  }
  if (pageData)
    return (
      <>
        <SEO
          title={
            pageData.title && pageData.title.text
              ? pageData.title.text + " | BluPlanet"
              : "Blog | BluPlanet"
          }
          description={
            pageData.subtitle
              ? pageData.subtitle
              : "BluPlanet Recycling is a locally owned and operated waste hauling company that offers reliable collections and great customer service. Since our start in 2009, we now service over 50,000 units with mixed recycling, garbage, and organic pickups while keeping true to our values and mission of striving for sustainability and excellent waste diversion."
          }
          image={pageData.blog_image && pageData.blog_image.url}
          imageAlt={pageData.blog_image && pageData.blog_image.alt}
        />
        <IntroSection
          id="blogPost-intro"
          data={{
            blogTag:
              pageData.blog_tag &&
              pageData.blog_tag.document &&
              pageData.blog_tag.document.data,
            bodyContent: pageData.subtitle,
            date: pageData.date,
            image: pageData.blog_image,
            title: pageData.title && pageData.title.text,
          }}
        />
        {/* Slice Zone */}
        {pageData.body &&
          pageData.body[0] &&
          pageData.body[0].slice_type &&
          pageData.body.map((slice, index) => {
            switch (slice.slice_type) {
              case "call_to_action":
                return (
                  <CallToAction
                    {...slice.primary}
                    items={slice.items}
                    key={"callToActionSlice" + index}
                  />
                )
              case "download":
                return (
                  <Download
                    {...slice.primary}
                    items={slice.items}
                    key={"downloadSlice" + index}
                  />
                )
              case "gallery":
                return (
                  <Gallery
                    {...slice.primary}
                    items={slice.items}
                    key={"gallerySlice" + index}
                  />
                )
              case "quote":
                return (
                  <Quote
                    {...slice.primary}
                    items={slice.items}
                    key={"quoteSlice" + index}
                  />
                )
              case "rich_text":
                return (
                  <RichText
                    {...slice.primary}
                    items={slice.items}
                    key={"richTextSlice" + index}
                  />
                )
              case "video":
                return (
                  <Video
                    {...slice.primary}
                    items={slice.items}
                    key={"videoSlice" + index}
                  />
                )
              default:
                return null
            }
          })}
        <NextPage
          id="blog-nextPage"
          image={
            data &&
            data.nextPost &&
            data.nextPost.data &&
            data.nextPost.data.blog_image
          }
          title={
            data &&
            data.nextPost &&
            data.nextPost.data &&
            data.nextPost.data.title &&
            data.nextPost.data.title.text
          }
          buttonLabel="Read more"
          buttonTo={
            "/blog/" + (data && data.nextPost && data.nextPost.uid) + "/"
          }
        />
      </>
    )
  else return null
}

export const query = graphql`
  query BlogPostQuery($uid: String!, $nextPost: ID!) {
    page: prismicBlogPost(uid: { eq: $uid }) {
      type
      uid
      data {
        blog_image {
          fluid(maxWidth: 600) {
            ...GatsbyPrismicImageFluid
          }
          alt
        }
        blog_tag {
          document {
            ... on PrismicBlogTag {
              data {
                color
                name
              }
            }
          }
        }
        date
        title {
          text
        }
        subtitle
        body {
          ... on PrismicBlogPostBodyCallToAction {
            slice_type
            primary {
              heading {
                text
              }
              button_label
              button_link {
                url
              }
              color
            }
          }
          ... on PrismicBlogPostBodyDownload {
            slice_type
            primary {
              name {
                text
              }
              description {
                html
              }
              file {
                url
              }
              image {
                fluid(maxWidth: 600) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
            }
          }
          ... on PrismicBlogPostBodyGallery {
            slice_type
            items {
              image {
                fluid(maxWidth: 600) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
            }
          }
          ... on PrismicBlogPostBodyQuote {
            slice_type
            primary {
              quote {
                text
              }
              color
            }
          }
          ... on PrismicBlogPostBodyRichText {
            slice_type
            primary {
              content {
                html
              }
            }
          }
          ... on PrismicBlogPostBodyVideo {
            slice_type
            primary {
              embed {
                embed_url
              }
            }
          }
        }
      }
    }
    nextPost: prismicBlogPost(prismicId: { eq: $nextPost }) {
      uid
      data {
        blog_image {
          fluid(maxWidth: 512) {
            ...GatsbyPrismicImageFluid
          }
          alt
        }
        title {
          text
        }
      }
    }
  }
`

export default BlogPostTemplate
