import React from "react"
import { mdiArrowRight } from "@mdi/js"

import { Box, Button, Flex, Heading, Link, Wrapper } from "components"
import { prismicColor } from "utils"

function CallToAction({ button_label, button_link, color, heading }) {
  if (heading && heading.text)
    return (
      <Wrapper>
        <Box bg={prismicColor(color).background} p={8} borderRadius={9999}>
          <Flex justifyContent="space-between">
            <Heading
              color={
                prismicColor(color).background === "wash" ? "" : "background"
              }
              size={600}
            >
              {heading.text}
            </Heading>
            {/* Button Link */}
            {(button_label || button_link) && (
              <Button
                as={Link}
                to={button_link}
                iconAfter={mdiArrowRight}
                intent="primary"
                appearance="primary"
              >
                {button_label}
              </Button>
            )}
          </Flex>
        </Box>
      </Wrapper>
    )
  else return null
}

export default CallToAction
