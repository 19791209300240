import React from "react"
import Image from "gatsby-image/withIEPolyfill"

import { Box, Flex, Wrapper } from "components"

function Gallery({ items }) {
  if (items && items[0] && items[0].image && items[0].image.fluid)
    return (
      <Wrapper>
        <Flex ml={[-16, -32]} mt={[-16, -32]} flexWrap="wrap">
          {items.map((item, index) => {
            if (item && item.image && item.image.fluid)
              return (
                <Box
                  width={items.length > 1 ? "50%" : "100%"}
                  pt={[16, 32]}
                  pl={[16, 32]}
                  key={"galleryItem" + index}
                >
                  <Image fluid={item.image.fluid} alt={item.image.alt} />
                </Box>
              )
          })}
        </Flex>
      </Wrapper>
    )
  else return null
}

export default Gallery
