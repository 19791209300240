import React from "react"
import Image from "gatsby-image/withIEPolyfill"
import { mdiDownload } from "@mdi/js"

import {
  AspectRatio,
  Box,
  Button,
  Flex,
  Heading,
  RichText,
  Wrapper,
} from "components"

function Download({ description, file, image, name }) {
  if (file && file.url)
    return (
      <Wrapper>
        <Flex
          flexDirection={["column", "row"]}
          bg="wash"
          borderRadius={8}
          p={[7, 8, 9]}
        >
          {/* Name and description */}
          <Box width={[1, 1 / 2]} pr={[0, 8]} pb={[6, 0]}>
            {name && name.text && (
              <Heading size={800} pb={6}>
                {name.text}
              </Heading>
            )}
            {description && description.html && (
              <RichText
                content={{
                  html: description.html,
                }}
                size={500}
                width="100%"
              />
            )}
          </Box>
          {/* Image and download button */}
          <Box width={[1, 1 / 2]}>
            <AspectRatio ratio="4:5" borderRadius={8} bg="wash">
              {image && image.fluid && (
                <Image
                  fluid={image.fluid}
                  alt={image.alt}
                  objectFit="contain"
                  objectPosition="center"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    width: "75%",
                    height: "75%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              )}
            </AspectRatio>
            {file && file.url && (
              <Flex justifyContent="center" mt="-20px">
                <Button
                  iconAfter={mdiDownload}
                  intent="default"
                  appearance="primary"
                  height={40}
                  as="a"
                  href={file.url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Download
                </Button>
              </Flex>
            )}
          </Box>
        </Flex>
      </Wrapper>
    )
  else return null
}

export default Download
